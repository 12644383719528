import React from "react";
import { Twitter, Linkedin, Pocket } from "react-feather";
import {
  LinkedinShareButton,
  TwitterShareButton,
  PocketShareButton,

} from "react-share";

const ShareButtons = ({ url, title, excerpt }) => (
  <div className="post-meta-share-icons">

    <LinkedinShareButton url={url} title={title} summary={excerpt}>
      <Linkedin strokeWidth={1.25} />
    </LinkedinShareButton>

    <TwitterShareButton url={url} title={title}>
      <Twitter strokeWidth={1.25} />
    </TwitterShareButton>

    <PocketShareButton url={url} title={excerpt}>
      <Pocket strokeWidth={1.25} />
    </PocketShareButton>
  </div>
);

export default ShareButtons;
